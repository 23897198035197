import { defineStore } from 'pinia'
import { File, Meta } from '@/models'
import { ref } from 'vue'
import { PaginationProps } from 'naive-ui'
import { API } from '@/api/api'

export const useFiles = defineStore({
  id: 'files',
  state: () => ({
    data: <any>[],
    meta: <Meta>{},
    params: null as any,
    order: 'desc',
    subgrupos: null as unknown,
    pagination: ref<PaginationProps>({
      page: 1,
      showSizePicker: true,
      pageCount: 1,
      pageSize: 20,
      pageSizes: [5, 10, 20, 40, 60, 100],
    }),
    isLoading: false,
    error: null as unknown,
  }),
  actions: {
    async fetch() {
      try {
        this.isLoading = true
        this.error = null
        const response = await API().get(
          `dashboard/arquivos?page=${this.pagination.page}&order=${this.order}&perPage=${this.pagination.pageSize}${this.params}`
        )
        this.data = response.data.data.map((file: any) => file as File)
        this.meta = response.data.meta as Meta
        await this.updatePagination()
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    updatePagination() {
      this.pagination = {
        ...this.pagination,
        page: this.meta.current_page,
        pageCount: this.meta.last_page,
        pageSize: this.meta.per_page,
      }
    },
    handlePageSizeChange(pageSize: number) {
      this.pagination = {
        ...this.pagination,
        pageSize,
        page: 1,
      }
      if (!this.isLoading) {
        this.fetch()
      }
    },
    resetPagination() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      }
    },
    handlePageChange(currentPage: number) {
      this.pagination = {
        ...this.pagination,
        page: currentPage,
      }
      console.log(this.pagination)
      if (!this.isLoading) {
        this.fetch()
      }
    },
    clearError() {
      this.error = null
    },
  },
})
