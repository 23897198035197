import { defineStore } from 'pinia'
import { API } from './../api/api'
import { Meta } from '@/models'
import { ref } from 'vue'
import { PaginationProps } from 'naive-ui'

export const useSites = defineStore({
  id: 'sites',
  state: () => ({
    data: <any>[],
    meta: <Meta>{},
    order: 'desc',
    params: null as any,
    pagination: ref<PaginationProps>({
      page: 1,
      showSizePicker: true,
      pageCount: 1,
      pageSize: 20,
      pageSizes: [5, 10, 20, 40, 60, 100],
    }),
    isLoading: false,
    error: null as unknown,
  }),
  actions: {
    async fetch(all = false) {
      try {
        this.isLoading = true
        let response
        if (!all) {
          response = await API().get(
            `dashboard/sites?page=${this.pagination.page}&order=${this.order}&perPage=${this.pagination.pageSize}${this.params}`
          )
          this.meta = response.data.meta as Meta
          this.data = response.data.data
          await this.updatePagination()
        } else {
          response = await API().get(`dashboard/sites?all=true`)
          this.data = response.data
        }
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    clearError() {
      this.error = null
    },
    updatePagination() {
      this.pagination = {
        ...this.pagination,
        page: this.meta.current_page,
        pageCount: this.meta.last_page,
        pageSize: this.meta.per_page,
      }
    },
    handlePageSizeChange(pageSize: number) {
      this.pagination = {
        ...this.pagination,
        pageSize,
        page: 1,
      }
      if (!this.isLoading) {
        this.fetch()
      }
    },
    handlePageChange(currentPage: number) {
      this.pagination = {
        ...this.pagination,
        page: currentPage,
      }
      if (!this.isLoading) {
        this.fetch()
      }
    },
    resetPagination() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      }
    },
  },
})
