import { defineStore } from 'pinia'
import { API } from '../api/api'
import { SiteSubgrupo } from '@/models'

export const useSiteSubgrupos = defineStore({
  id: 'site-subgrupos',
  state: () => ({
    data: <SiteSubgrupo[]>[],
    isLoading: false,
    error: null as unknown,
  }),
  actions: {
    async fetchBySite(idSite: number) {
      try {
        this.isLoading = true
        const response = await API().get(
          `dashboard/site-subgrupos/${idSite}/site`
        )
        this.data = response.data.map((file: any) => file as SiteSubgrupo)
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    clearError() {
      this.error = null
    },
  },
})
