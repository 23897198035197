import axios, { AxiosInstance } from 'axios'
import { API_URL } from './../config'

export function API(): AxiosInstance {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}')
  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `bearer ${user != null ? user.token : ''}` },
  })
  axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error.response.status >= 500) {
  //       console.log('Erro no serivodor')
  //       return Promise.reject(error.response)
  //     }
  //     return Promise.reject(error.response)
  //   }
  // )
  return axiosInstance
}

export function APIUnsecure(): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: API_URL,
  })

  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error.response.status >= 500) {
  //       console.log('Erro no serivodor')
  //       return Promise.reject(error.response)
  //     }
  //     return error.response
  //   }
  // )

  return axiosInstance
}

export default API()
