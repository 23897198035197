export const APP_NAME = 'Dashboard - Transparência'
export const BASE_URL = 'https://transpsync.anc-app.xyz/'
export const API_URL = 'https://transpsync.anc-app.xyz/'
// export const BASE_URL = 'http://127.0.0.1:3334/'
// export const API_URL = 'http://127.0.0.1:3334/'
export const ANO = new Date().getFullYear()
export const TIPO = 'Transparência'

export const IMAGE_URL = 'https://cmpfg.am.gov.br/'

export function openLink(link: string): void {
  window.open(link, '_blank')
}
