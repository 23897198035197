import { defineStore } from 'pinia'
import { APIUnsecure } from './../api/api'
import routes from '@/router/index'

class UserModel {
  email: string
  token: string
  constructor(data: Record<string, string>) {
    this.email = data.email
    this.token = data.token
  }
}

export const useAuth = defineStore({
  id: 'auth',
  state: () => ({
    user: null as UserModel | null,
    isAuthenticated: false,
    redirectOTP: false,
    qrCode: null as string | null,
    otpCode: null as string | null,
    otpToken: null as string | null,
    isLoading: false,
    error: null as any,
    autarquia: null,
  }),
  actions: {
    async login({ email, password }: { email: string; password: string }) {
      try {
        this.isLoading = true
        this.error = null
        const response = await APIUnsecure().post('auth/signin', {
          email,
          password,
        })
        if (response.data.qr != undefined) {
          this.redirectOTP = true
          this.qrCode = response.data.qrCode
          this.isAuthenticated = false
        } else {
          this.user = new UserModel(response.data)
          this.autarquia = response.data.autarquia
          this.isAuthenticated = true
          this.isLoading = false
        }
      } catch (error) {
        console.log(error)
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    clearError() {
      this.error = null
    },
  },
  persist: true,
})
